.App {
  text-align: center;
  font-family: serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
}

header {
  padding: .5em;
  display: flex;
  width: 70%;
  font-size: 1.5em;
}

header > div {
  flex: 1;
  margin-bottom: 1em;
}
header .byline {
  font-size: .75em;
  font-weight: bold;
}

@media (max-width: 30em) {
  header {
    flex-direction: column;
  }
}

h3 {
  margin: 0;
  padding-bottom: 0;
}
h3+p {
  margin: 0;
  padding: 0;
}

.svg-logo {
  pointer-events: none;
  fill: white;
  filter: drop-shadow( 3px 3px 2px rgba(175, 91, 0, .7));
}

.App-logo {
  /*animation: App-logo-spin infinite 20s linear;*/
  height: 10vmin;
  min-height: 10vmin;
}

.App-time-and-place {
  height: 4vmin;
  min-height: 1em;
}

.App-byline {
  height: 2.5vmin;
  min-height: 1em;
}

.player {
  margin: 20px;
  height: 35vw;
  max-width: 90%;
}
.player > div {
  background-color: transparent !important;
}

p {
  max-width: 70%;
  text-align: left;
}

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

.App-link {
  color: #61dafb;
}

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotateY(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotateY(360deg);*/
/*  }*/
/*}*/
